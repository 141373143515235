.title {
  font-weight: bold;
  font-size: x-large;

}

.titleundertext1 {
  font-size: x-large !important;
  font-family: 'Rammetto One' !important;
  text-align: center !important;
  font-weight: bold;
  
 

}

.waterloo {
  font-size: x-large !important;
  font-family: 'Rammetto One' !important;
  font-weight: bold !important;

  color: #FFE5B4 !important;
}

.backgroundaboutme {
  background: linear-gradient(#131862, #2e4482);
  padding: 60px 0 200px 0;

  width:100%;
  background: #4B79A1;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #283E51, #0A2342);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #283E51, #0A2342); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: -olinear-gradient(to top, #283E51, #0A2342); 
  height: 100%;

}
.sky {
 
  width:100%;
  background: #4B79A1;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #283E51, #366bac);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #36638b, #0A2342); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: -olinear-gradient(to top, #283E51, #0A2342); 
  height: 100vh;
}

.stars {
  
  top:0;
  left:0;
  right:0;
  bottom:10;
  width:100%;
  height:100%;
  display:block;
  background:url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center;
  z-index:0;
  
}

h3 {

  text-align: center !important;
  font-size: 35px !important;
  color: #fff !important;
  width: 55% !important;
  margin: 5rem auto !important;
  line-height: 3rem !important;
  letter-spacing: 5px !important;
}

/*****************************************
Check out Nat's pen with animated SVG weather icons. 

https://codepen.io/nsayenko/pen/JKzxdm/
*******************************************/



.contact1 {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #70a312 111.58%);
  padding: 60px 0 200px 0;
  width:100%;
  background: #4B79A1;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #283E51, #3779c8);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #283E51, #0A2342); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: -olinear-gradient(to top, #283E51, #0A2342); 
  height: 100vh;
  
}

.contact1 img {
  width: 92%;
}

.contact1 h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact1 form input,
.contact1 form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact1 form input:focus,
.contact1 form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact1 form input::placeholder,
.contact1 form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact1 form input:focus::placeholder,
.contact1 form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact1 form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact1 form button span {
  z-index: 1;
  position: relative;
}

.contact1 form button:hover {
  color: #fff;
}

.contact1 form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact1 form button:hover::before {
  width: 100%;
}




.title {
  font-weight: bold !important;
  font-size: x-large ;
}





.backgroundaboutme {
  background: linear-gradient(#131862, #2e4482);
  padding: 60px 0 200px 0;
  width:100%;
  background: #4B79A1;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #283E51, #0A2342);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #283E51, #0A2342); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: -olinear-gradient(to top, #283E51, #0A2342); 
  height: 100vh;
}

.sky {
  width:100%;
  background: #4B79A1;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #283E51, #0A2342);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #283E51, #0A2342); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: -olinear-gradient(to top, #283E51, #0A2342); 
  height: 100vh;
}

.stars {
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:100%;
  display:block;
  background:url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center;
  z-index:0;
  position: relative;
  overflow: visible;
}

@keyframes animateStars {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.stars::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center;
  animation: animateStars 15s linear infinite;
}

h1.title {
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
  margin-top: 100px;
}

.contact1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #70a312 111.58%);
  padding: 60px 0 200px 0;
  width:100%;
  background: #4B79A1;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #283E51, #0A2342);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #283E51, #0A2342); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: -olinear-gradient(to top, #283E51, #0A2342); 
  height: 100vh;
}

.contact1 img {
  width: 92%;
}

.contact1 h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact1 form input,
.contact1 form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact1 form input:focus,
.contact1 form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact1 form input::placeholder,
.contact1 form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact1 form input:focus::placeholder,
.contact1 form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact1 form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact1 form button span {
  z-index: 1;
  position: relative;
}

.contact1 form button:hover {
  color: #fff;
}

.contact1 form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact1 form button:hover::before {
  width: 100%;
}
